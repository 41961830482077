import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import PageContent from './Util/PageContent';
import TextContent from './ui/TextContent';
import { ContentRender } from './ContentRender/ContentRender';
import AddwishRow from './ui/AddwishRow/AddwishRow';
import { config } from '../shop.config.custom';
import SiteContentContext from './SiteContent/SiteContentContext';
import { useLocation } from 'react-router';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useAddwish } from '@jetshop/flight-addwish';

const Wrapper = styled('div')`
  padding: 0 0 5rem 0;
`;

const ErrorContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 4vh 2rem;
  align-items: center;
`;

const ErrorTitle = styled('h1')`
  margin: 0 0 1rem 0 !important;
`;

const ErrorBody = styled('div')`
  color: #555;
  max-width: 700px;
  padding-bottom: 1em;
`;

const NotFoundPage = () => {
  const location = useLocation();
  const { selectedChannel } = useContext(ChannelContext);
  const url = selectedChannel.url + location.pathname;
  const { retargetTitle, topProductsTitle } = useContext(SiteContentContext);

  //ADDWISH
  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [config.addwish.notFound.retarget]: {
        url: url,
      },
      [config.addwish.notFound.top]: {
        url: url,
      },
    },
    pageLoaded: true,
  });

  return (
    <PageContent pageId={223}>
      {(page) => (
        <Wrapper>
          <ErrorContainer>
            <ErrorTitle>{page && page.name}</ErrorTitle>
            <ErrorBody>
              {page?.content && <TextContent content={page.content} />}
            </ErrorBody>
          </ErrorContainer>
          {page?.data && <ContentRender data={page.data} />}
          <AddwishRow
            data={addWishResponseBoxItems[config.addwish.notFound.retarget]}
            id={config.addwish.notFound.retarget}
            slider={true}
            title={retargetTitle}
          />
          <AddwishRow
            data={addWishResponseBoxItems[config.addwish.notFound.top]}
            id={config.addwish.notFound.top}
            slider={true}
            title={topProductsTitle}
          />
        </Wrapper>
      )}
    </PageContent>
  );
};

export default NotFoundPage;
